import { createTheme, ThemeOptions } from '@material-ui/core'
import {
  PaletteOptions,
  TypeBackground,
} from '@material-ui/core/styles/createPalette'
import { SimplePaletteColorOptions, TypeText } from '@mui/material'
import { TypographyOptions } from '@material-ui/core/styles/createTypography'
import { Overrides } from '@material-ui/core/styles/overrides'

export const headerFontFamily =
  '"Helvetica Neue", "Fira Sans", "Helvetica", sans-serif'
export const gintoHeaderFontFamily =
  '"GintoBlack", "Montserrat", "Helvetica Neue", "Fira Sans", "Helvetica", sans-serif'
export const fontFamily =
  '-apple-system, "BlinkMacSystemFont", "Roboto", "Arial", sans-serif'

const containedOutlineButtonStyle = {
  textTransform: 'none' as const,
  letterSpacing: '0.0125rem',
  fontSize: '0.875rem',
  lineHeight: '0.875rem',
  height: 40,
  borderRadius: 1000,
  backgroundColor: '#FFFFFF',
  boxShadow:
    '0px 3px 1px -2px rgba(0,0,0,0.06), 0px 2px 2px 0px rgba(0,0,0,0.04), 0px 1px 5px 0px rgba(0,0,0,0.04)',
  '&:hover': {
    boxShadow:
      '0px 2px 4px -1px rgba(0,0,0,0.06), 0px 4px 5px 0px rgba(0,0,0,0.04), 0px 1px 10px 0px rgba(0,0,0,0.04)',
  },
}

interface ExtendedPaletteColorOptions extends SimplePaletteColorOptions {
  darker?: string
  wizard?: string
  lighter?: string
  lightDark?: string
  lightest?: string
  halfTransparent?: string
  button?: string
  lightOnSurface?: string
  light?: string
  background?: string
  surface?: string
  textPrimary?: string
  textSecondary?: string
  10?: string
  20?: string
  40?: string
  50?: string
  95?: string
  100?: string
  200?: string
  300?: string
  400?: string
  500?: string
  600?: string
  800?: string
  900?: string
  950?: string
}

interface ExtendedBackground extends TypeBackground {
  backdrop: string
  modalBorder: string
}

interface ExtendedPaletteOptions extends PaletteOptions {
  primary: ExtendedPaletteColorOptions
  secondary: ExtendedPaletteColorOptions
  text: Partial<TypeText>
  red: ExtendedPaletteColorOptions
  yellow: ExtendedPaletteColorOptions
  gray: ExtendedPaletteColorOptions
  tertiary: ExtendedPaletteColorOptions
  green: ExtendedPaletteColorOptions
  darkMode: ExtendedPaletteColorOptions
  background: ExtendedBackground
  pink: ExtendedPaletteColorOptions
}

const grey = {
  light: '#F5F5F5',
  main: '#EBEBEB',
  dark: '#E9E9E9',
  lightOnSurface: '#F0F0F0',
  100: '#FAFAFA',
  150: '#F5F5F5',
  200: '#EBEBEB',
  300: '#E6E6E6',
  500: '#CCCCCC',
  600: '#A6A6A6',
  700: '#7E7E7E',
  800: '#4D4D4D',
  900: '#1F1F1F',
}

export const paletteSecondary = {
  main: '#8B30F3',
  light: '#C495F9',
  dark: '#600BC1',
  lighter: '#E9D8FD',
  lightDark: '#600BC1',
  800: '#600BC1',
  950: '#240448',
  100: '#F7F1FE',
  200: '#E9D8FD',
  400: '#C495F9',
}

const palette: ExtendedPaletteOptions = {
  text: {
    secondary: 'rgba(0,0,0,0.6)',
  },
  background: {
    default: '#FFFFFF',
    wizard: '#E5E5E5',
    paper: '#FFFFFF',
    backdrop: 'rgba(250, 250, 250, 0.5)',
    modalBorder: 'none',
  },
  primary: {
    // main: '#3F6AD4',
    main: '#2E60DC',
    lighter: '#819FEA',
    light: '#F2F5FD',
    lightDark: '#18398C',
    dark: '#0B1B41',
    halfTransparent: 'rgba(25,123,189,0.5)',
    100: '#F2F5FD',
    400: '#819FEA',
    600: '#18398C',
    50: '#F2F5FD',
    950: '#0B1B41',
    200: '#D3DEF8',
    800: '#18398C',
  },
  secondary: paletteSecondary,
  tertiary: {
    main: '#CEFF2D',
    950: '#0F231A',
    100: '#F4FFD0',
    50: '#FBFFEE',
  },
  green: {
    dark: '#002C0E',
    main: '#A373F1',
    light: '#F3ECFD',
  },
  red: {
    darker: '#2C0000',
    dark: '#D13E3B',
    main: '#EE6C4D',
    light: '#FDECEC',
    lighter: '#F3947C',
    lightDark: '#f6dbdb',
    lightest: '#FFEFEF',
    contrastText: '#fff',
    400: '#FFAB99',
    95: '#5C1100',
    20: '#FFD5CC',
    10: '#FFF2F0',
  },
  yellow: {
    dark: '#A88E0E',
    main: '#2E60DC',
    light: '#F2F5FD',
    button: '#FFEB84',
    lightDark: '#f6f4c8',
  },
  pink: {
    main: '#ED78ED',
    800: '#901490',
    100: '#FDF2FD',
  },
  gray: grey,
  grey: grey,
  darkMode: {
    main: '',
    background: '#121212',
    surface: '#272727',
    button: '#333333',
    textPrimary: '#FFFFFF',
    textSecondary: '#ABABAB',
  },
  action: {
    disabled: 'rgba(0, 0, 0, 0.5)',
  },
}

const greyDark = {
  ...grey,
  100: grey[900],
  150: grey[900],
  200: grey[800],
  500: grey[800],
  700: grey[500],
  800: grey[100],
  // 100: '#F5F5F5',
  // 200: '#EBEBEB',
  // 300: '#E6E6E6',
  // 500: '#CCCCCC',
  // 600: '#A6A6A6',
  // 800: '#4D4D4D',
  // 900: '#1F1F1F',
}

const darkPalette: ExtendedPaletteOptions = {
  ...palette,
  text: {
    primary: '#ffffff',
    secondary: '#F5F5F5',
  },
  background: {
    ...palette.background,
    default: '#111111',
    paper: '#111111',
    backdrop: 'rgba(0, 0, 0, 0.5)',
    modalBorder: `1px solid ${greyDark[200]}`,
  },
  grey: greyDark,
  gray: greyDark,
  secondary: {
    ...palette.secondary,
    main: palette.secondary[100],
    100: '#3E146E',
    200: '#600BC1',
    800: palette.secondary[200],
    950: palette.secondary[100],
  },
  primary: {
    ...palette.primary,
    100: palette.primary[950],
    200: palette.primary[800],
    800: palette.primary[200],
    950: palette.primary[200],
  },
}

const typography: TypographyOptions = {
  h2: {
    fontFamily: gintoHeaderFontFamily,
    fontSize: '3rem',
    lineHeight: '2.7rem',
    fontWeight: 900,
    letterSpacing: '-0.06rem',
  },
  h3: {
    fontFamily: gintoHeaderFontFamily,
    fontSize: '2rem',
    lineHeight: '1.8rem',
    fontWeight: 900,
    letterSpacing: '-0.04rem',
  },
  h4: {
    fontFamily: gintoHeaderFontFamily,
    fontSize: '1.5rem',
    lineHeight: '1.35rem',
    fontWeight: 900,
    letterSpacing: '0.003rem',
  },
  h5: {
    // fontFamily: headerFontFamily,
    fontSize: '1.5rem',
    lineHeight: '1.5rem',
    fontWeight: 700,
  },
  h6: {
    // fontFamily: headerFontFamily,
    fontSize: '1.25rem',
    fontWeight: 700,
    lineHeight: '23.87px',
  },
  subtitle1: {
    // fontFamily: headerFontFamily,
    fontSize: '1rem',
    lineHeight: '1rem',
    fontWeight: 700,
  },
  subtitle2: {
    // fontFamily: headerFontFamily,
    fontSize: '0.875rem',
    lineHeight: '0.875rem',
    fontWeight: 600,
  },
  body1: {
    fontSize: '1rem',
    lineHeight: '1.195rem',
    whiteSpace: 'pre-wrap',
  },
  body2: {
    fontSize: '0.875rem',
    lineHeight: '16.71px',
    letterSpacing: '0.016rem',
    whiteSpace: 'pre-wrap',
  },
  body2Medium: {
    fontSize: '0.875rem',
    lineHeight: '0.875rem',
    fontWeight: 500,
    whiteSpace: 'pre-wrap',
  },
  caption: {
    fontSize: '0.75rem',
    lineHeight: '0.875rem',
    letterSpacing: '0.0125rem',
    whiteSpace: 'pre-wrap',
  },
  button: {
    textTransform: 'none',
    fontWeight: 600,
    letterSpacing: '0.0125rem',
    fontSize: '0.875rem',
    lineHeight: '0.875rem',
  },
  overline: {
    letterSpacing: '0.094rem',
    fontSize: '0.625rem',
    lineHeight: '0.625rem',
    textTransform: 'uppercase',
    fontWeight: 500,
  },
  fontFamily: fontFamily,
  useNextVariants: true,
}

const overrides: Overrides = {
  MuiPaper: {
    rounded: {
      borderRadius: 8,
    },
    elevation4: {
      boxShadow:
        '0 4px 5px 0 rgba(0, 0, 0, 0.04), 0 1px 10px 0 rgba(0, 0, 0, 0.04), 0 2px 4px -1px rgba(0, 0, 0, 0.06)',
    },
    elevation8: {
      boxShadow:
        '0px 3px 1px -2px rgba(0,0,0,0.06),0px 2px 2px 0px rgba(0,0,0,0.04),0px 1px 5px 0px rgba(0,0,0,0.04)',
    },
  },
  MuiButton: {
    containedSizeSmall: {
      height: 32,
    },
    containedSizeLarge: {
      height: 48,
    },
    containedPrimary: {
      color: '#FFFFFF',
    },
    containedSecondary: {
      color: '#FFFFFF !important',
    },
    contained: containedOutlineButtonStyle,
    outlined: containedOutlineButtonStyle,
  },
  MuiInputLabel: {
    shrink: {
      fontWeight: 'bold',
      color: '#3F6AD4',
    },
  },
  MuiDivider: {
    root: {
      backgroundColor: '#F0F0F0',
    },
  },
}

const sharedThemeOptions: ThemeOptions = {
  breakpoints: {
    values: {
      container: 480,
      visual: 752,
      header: 824,
      xs: 0,
      sm: 400,
      md: 640,
      lg: 960,
      xl: 1280,
      xxl: 1920,
      events: 520,
    },
  },
  containerMaxWidth: 820,
  sidebar: {
    width: 200,
  },
  header: {
    height: 56,
  },
  drawer: {
    width: 400,
    showFrom: 'xxxl', // 'xl'
  },
  shadows: [
    'none',
    '0px 2px 1px -1px rgba(0,0,0,0.06),0px 1px 1px 0px rgba(0,0,0,0.04),0px 1px 3px 0px rgba(0,0,0,0.04)',
    '0px 3px 1px -2px rgba(0,0,0,0.06),0px 2px 2px 0px rgba(0,0,0,0.04),0px 1px 5px 0px rgba(0,0,0,0.04)',
    '0px 3px 3px -2px rgba(0,0,0,0.06),0px 3px 4px 0px rgba(0,0,0,0.04),0px 1px 8px 0px rgba(0,0,0,0.04)',
    '0px 2px 4px -1px rgba(0,0,0,0.06),0px 4px 5px 0px rgba(0,0,0,0.04),0px 1px 10px 0px rgba(0,0,0,0.04)',
    '0px 3px 5px -1px rgba(0,0,0,0.06),0px 5px 8px 0px rgba(0,0,0,0.04),0px 1px 14px 0px rgba(0,0,0,0.04)',
    '0px 3px 5px -1px rgba(0,0,0,0.06),0px 6px 10px 0px rgba(0,0,0,0.04),0px 1px 18px 0px rgba(0,0,0,0.04)',
    '0px 4px 5px -2px rgba(0,0,0,0.06),0px 7px 10px 1px rgba(0,0,0,0.04),0px 2px 16px 1px rgba(0,0,0,0.04)',
    '0px 5px 5px -3px rgba(0,0,0,0.06),0px 8px 10px 1px rgba(0,0,0,0.04),0px 3px 14px 2px rgba(0,0,0,0.04)',
    '0px 5px 6px -3px rgba(0,0,0,0.06),0px 9px 12px 1px rgba(0,0,0,0.04),0px 3px 16px 2px rgba(0,0,0,0.04)',
    '0px 6px 6px -3px rgba(0,0,0,0.06),0px 10px 14px 1px rgba(0,0,0,0.04),0px 4px 18px 3px rgba(0,0,0,0.04)',
    '0px 6px 7px -4px rgba(0,0,0,0.06),0px 11px 15px 1px rgba(0,0,0,0.04),0px 4px 20px 3px rgba(0,0,0,0.04)',
    '0px 7px 8px -4px rgba(0,0,0,0.06),0px 12px 17px 2px rgba(0,0,0,0.04),0px 5px 22px 4px rgba(0,0,0,0.04)',
    '0px 7px 8px -4px rgba(0,0,0,0.06),0px 13px 19px 2px rgba(0,0,0,0.04),0px 5px 24px 4px rgba(0,0,0,0.04)',
    '0px 7px 9px -4px rgba(0,0,0,0.06),0px 14px 21px 2px rgba(0,0,0,0.04),0px 5px 26px 4px rgba(0,0,0,0.04)',
    '0px 8px 9px -5px rgba(0,0,0,0.06),0px 15px 22px 2px rgba(0,0,0,0.04),0px 6px 28px 5px rgba(0,0,0,0.04)',
    '0px 8px 10px -5px rgba(0,0,0,0.06),0px 16px 24px 2px rgba(0,0,0,0.04),0px 6px 30px 5px rgba(0,0,0,0.04)',
    '0px 8px 11px -5px rgba(0,0,0,0.06),0px 17px 26px 2px rgba(0,0,0,0.04),0px 6px 32px 5px rgba(0,0,0,0.04)',
    '0px 9px 11px -5px rgba(0,0,0,0.06),0px 18px 28px 2px rgba(0,0,0,0.04),0px 7px 34px 6px rgba(0,0,0,0.04)',
    '0px 9px 12px -6px rgba(0,0,0,0.06),0px 19px 29px 2px rgba(0,0,0,0.04),0px 7px 36px 6px rgba(0,0,0,0.04)',
    '0px 10px 13px -6px rgba(0,0,0,0.06),0px 20px 31px 3px rgba(0,0,0,0.04),0px 8px 38px 7px rgba(0,0,0,0.04)',
    '0px 10px 13px -6px rgba(0,0,0,0.06),0px 21px 33px 3px rgba(0,0,0,0.04),0px 8px 40px 7px rgba(0,0,0,0.04)',
    '0px 10px 14px -6px rgba(0,0,0,0.06),0px 22px 35px 3px rgba(0,0,0,0.04),0px 8px 42px 7px rgba(0,0,0,0.04)',
    '0px 11px 14px -7px rgba(0,0,0,0.06),0px 23px 36px 3px rgba(0,0,0,0.04),0px 9px 44px 8px rgba(0,0,0,0.04)',
    '0px 11px 15px -7px rgba(0,0,0,0.06),0px 24px 38px 3px rgba(0,0,0,0.04),0px 9px 46px 8px rgba(0,0,0,0.04)',
  ],
}

declare module '@material-ui/core/styles/createTheme' {
  interface Theme {
    containerMaxWidth?: number | string
    sidebar?: any
  }
  // allow configuration using `createMuiTheme`
  interface ThemeOptions {
    containerMaxWidth?: number | string
    sidebar?: any
    header?: any
    drawer?: any
  }
}

declare module '@material-ui/core/styles/createPalette' {
  interface TypeBackground {
    wizard: string
  }
  interface Palette {
    yellow: ExtendedPaletteColorOptions
    red: ExtendedPaletteColorOptions
    gray: ExtendedPaletteColorOptions
  }
  interface PaletteOptions {
    yellow?: ExtendedPaletteColorOptions
    red?: ExtendedPaletteColorOptions
    gray?: ExtendedPaletteColorOptions
  }

  interface Color {
    lightOnSurface?: string
    light?: string
    lighter?: string
    darker?: string
    lightdark?: string
  }

  interface PaletteColor extends ExtendedPaletteColorOptions {
    lightOnSurface?: string
    lighter?: string
    darker?: string
    button?: string
    lightdark?: string
  }
}

declare module '@material-ui/core/styles/createTypography' {
  interface TypographyOptions {
    body2Medium?: React.CSSProperties
    useNextVariants?: boolean
  }
  interface Typography {
    body2Medium: React.CSSProperties
    useNextVariants?: boolean
  }
}

declare module '@material-ui/core/styles/createBreakpoints' {
  interface BreakpointOverrides {
    container: true
    visual: true
    header: true
    events: true
    xxl: true
  }
}

export default createTheme({
  typography,
  props: {
    MuiTypography: {
      variantMapping: {
        caption: 'p',
        // overline: 'p'
      },
    },
  },
  palette: palette,
  overrides,
  ...sharedThemeOptions,
})

export const darkTheme = createTheme({
  typography,
  props: {
    MuiTypography: {
      variantMapping: {
        caption: 'p',
        // overline: 'p'
      },
    },
  },
  palette: darkPalette,
  overrides,
  ...sharedThemeOptions,
})
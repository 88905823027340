import apiClient from 'shared-components/utils/ApiClient'
import { patchLocalOrganisation } from './organisation'
import { setSnackbar } from 'shared-components/redux/notifications/actions'
import mixpanel from 'shared-components/utils/mixpanel'

export const FETCH_USER = 'FETCH_USER'
export const FETCH_USER_BACKGROUND = 'FETCH_USER_BACKGROUND'
export const FETCH_USER_PROFILE = 'FETCH_USER_PROFILE'
export const FETCH_FRIENDS = 'FETCH_FRIENDS'
export const FETCH_USER_BY_CODE = 'FETCH_USER_BY_CODE'
export const REMOVE_FROM_USER = 'REMOVE_FROM_USER'
export const FETCH_FOLLOWING = 'FETCH_FOLLOWING'
export const FETCH_PUBLIC_EVENTS = 'FETCH_PUBLIC_EVENTS'
export const FETCH_USER_EVENTS = 'FETCH_USER_EVENTS'
export const FETCH_PAST_USER_EVENTS = 'FETCH_PAST_USER_EVENTS'
export const REMOVE_FOLLOWING = 'REMOVE_FOLLOWING'
export const REMOVE_FRIEND = 'REMOVE_FRIEND'
export const ADD_FRIEND = 'ADD_FRIEND'
export const REMOVE_FRIEND_SUGGESTION = 'REMOVE_FRIEND_SUGGESTION'
export const ADD_FOLLOWING = 'ADD_FOLLOWING'
export const SET_FROM_USER_CODE = 'SET_FROM_USER_CODE'
export const UPLOAD_USER_AVATAR = 'UPLOAD_USER_AVATAR'
export const PATCH_USER = 'PATCH_USER'
export const LOGOUT_USER = 'LOGOUT_USER'
export const FETCH_USER_ORGANISATIONS = 'FETCH_USER_ORGANISATIONS'
export const ADD_USER_ORGANISATION = 'ADD_USER_ORGANISATION'
export const CREATED_ORGANISATION = 'CREATED_ORGANISATION'
export const RSVP_FOLLOWING_EVENT = 'RSVP_FOLLOWING_EVENT'
export const FETCH_FRIEND_SUGGESTIONS = 'FETCH_FRIEND_SUGGESTIONS'
export const FETCH_FRIEND_REQUESTS = 'FETCH_FRIEND_REQUESTS'
export const REMOVE_FRIEND_REQUEST = 'REMOVE_FRIEND_REQUEST'
export const FETCH_NOTIFICATION_SETTINGS = 'FETCH_NOTIFICATION_SETTINGS'
export const PATCH_NOTIFICATION_SETTINGS = 'PATCH_NOTIFICATION_SETTINGS'
export const FETCH_HOME_EVENTS = 'FETCH_HOME_EVENTS'
export const FETCH_FUTURE_EVENTS = 'FETCH_FUTURE_EVENTS'
export const FETCH_HOSTED_EVENTS = 'FETCH_HOSTED_EVENTS'
export const FETCH_HOSTED_PAST_EVENTS = 'FETCH_HOSTED_PAST_EVENTS'
export const RESET_HOSTED_EVENTS = 'RESET_HOSTED_EVENTS'
export const FETCH_PAST_EVENTS = 'FETCH_PAST_EVENTS'
export const FETCH_TBA_EVENTS = 'FETCH_TBA_EVENTS'
export const FETCH_TODAY_EVENTS = 'FETCH_TODAY_EVENTS'
export const FETCH_INVITE_EVENTS = 'FETCH_INVITE_EVENTS'
export const FETCH_OPEN_EVENTS = 'FETCH_OPEN_EVENTS'
export const FETCH_ACTIVE_EVENTS = 'FETCH_ACTIVE_EVENTS'
export const FETCH_CALENDAR_EVENTS = 'FETCH_CALENDAR_EVENTS'
export const FETCH_CATEGORIES = 'FETCH_CATEGORIES'
export const SET_HAS_AVATAR = 'SET_HAS_AVATAR'
export const INCREMENT_IMAGE_VERSION = 'INCREMENT_IMAGE_VERSION'

const homeImgOptions = {
  eventImageSize: 'card-xs',
  orgAvatarSize: 'sm',
  avatarSize: 'sm',
}
const publicImgOptions = {
  eventImageSize: 'card-lg',
  orgAvatarSize: 'sm',
  avatarSize: 'xs',
}

export const fetchUser = () => (dispatch, getState) => {
  dispatch({
    type: FETCH_USER,
    payload: apiClient.user.getMe(),
  })
}

export const patchUser = (user) => ({
  type: PATCH_USER,
  payload: apiClient.user.patchMe(user),
})

export const fetchUserBackground = (user) => ({
  type: FETCH_USER_BACKGROUND,
  payload: apiClient.user.getMe(),
})

export const incrementImageVersion = () => ({
  type: INCREMENT_IMAGE_VERSION,
})

export const logoutUserLocal = () => async (dispatch) => {
  await apiClient.user.signMeOut()
  logoutUserClientSide(dispatch, null, true)
}

export const logoutUser = (redirectTo) => async (dispatch) => {
  await apiClient.user.signMeOut()
  logoutUserClientSide(dispatch, redirectTo)
}

export const logoutUserClientSide = (dispatch, redirectTo, noRedirect) => {
  mixpanel.unregister('User Has Avatar')
  mixpanel.unregister('User Name')
  mixpanel.unregister('User Weekly Update')
  // Remove cookies and localstorage
  document.cookie.split(';').forEach(function (c) {
    document.cookie = c
      .replace(/^ +/, '')
      .replace(/=.*/, '=;expires=' + new Date().toUTCString() + ';path=/')
  })
  window.localStorage.clear()
  if (typeof window !== 'undefined' && !noRedirect) {
    window.open(redirectTo ?? '/', '_self')
  }
  // Router.push('/signIn')
  dispatch({
    type: LOGOUT_USER,
  })
}

export const setUser = (user) => ({
  type: `${FETCH_USER}_FULFILLED`,
  payload: user,
})

export const fetchUserProfile = () => ({
  type: FETCH_USER_PROFILE,
  payload: apiClient.user.getMyProfile(),
})

export const fetchFriends = () => ({
  type: FETCH_FRIENDS,
  payload: apiClient.user.getFriends(),
})

export const fetchUserByCode = (code) => ({
  type: FETCH_USER_BY_CODE,
  payload: apiClient.user.getUserByCode(code),
})

export const setFromUserCode = (code) => ({
  type: SET_FROM_USER_CODE,
  payload: code,
})

export const removeFromUser = () => ({
  type: REMOVE_FROM_USER,
  payload: null,
})

export const fetchPublicEvents = (page) => ({
  type: FETCH_PUBLIC_EVENTS,
  payload: apiClient.event.getPublic(),
})

export const fetchEvents = () => ({
  type: FETCH_USER_EVENTS,
  payload: apiClient.event.getMine(),
})

export const fetchHomeEvents = (page, size) => ({
  type: FETCH_HOME_EVENTS,
  payload: apiClient.event.getHome(page, size),
})

export const fetchFutureEvents = (page, size) => ({
  type: FETCH_FUTURE_EVENTS,
  payload: apiClient.event.getFuture(page, size),
})

export const fetchHosted = (page, size, past) => ({
  type: past ? FETCH_HOSTED_PAST_EVENTS : FETCH_HOSTED_EVENTS,
  payload: { promise: apiClient.event.getHosted(page, size, past), data: 0 },
})

export const fetchHostedOrg = (orgId, page, size, past) => ({
  type: past ? FETCH_HOSTED_PAST_EVENTS : FETCH_HOSTED_EVENTS,
  payload: {
    promise: past
      ? apiClient.organisation.getPastEvents(orgId, page, size, past)
      : apiClient.organisation.getEvents(orgId, page, size, past),
    data: orgId,
  },
})

export const resetHosted = (key) => ({
  type: RESET_HOSTED_EVENTS,
  payload: key,
})

export const setHasAvatar = (hasAvatar) => ({
  type: SET_HAS_AVATAR,
  payload: hasAvatar,
})

export const fetchPastEvents = (page, size) => ({
  type: FETCH_PAST_EVENTS,
  payload: apiClient.event.getMinePast(page, size),
})

export const fetchTbaEvents = (page, size) => ({
  type: FETCH_TBA_EVENTS,
  payload: apiClient.event.getTba(page, size),
})

export const fetchTodayEvents = (page, size) => ({
  type: FETCH_TODAY_EVENTS,
  payload: apiClient.event.getToday(page, size),
})

export const fetchInviteEvents = (page, size) => ({
  type: FETCH_INVITE_EVENTS,
  payload: apiClient.event.getInvites(page, size),
})

export const fetchOpenEvents = (page, size) => ({
  type: FETCH_OPEN_EVENTS,
  payload: apiClient.event.getOpen(page, size),
})

export const fetchActiveEvents = (page, size) => ({
  type: FETCH_ACTIVE_EVENTS,
  payload: apiClient.event.getActive(page, size),
})

export const fetchCalendarEvents = (page, size) => ({
  type: FETCH_CALENDAR_EVENTS,
  payload: apiClient.event.getCalendar(page, size),
})

// export const fetchPastEvents = () => ({
//   type: FETCH_PAST_USER_EVENTS,
//   payload: apiClient.event.getMinePast(0, 100)
// })

export const fetchOrganisations = () => ({
  type: FETCH_USER_ORGANISATIONS,
  payload: apiClient.organisationAdmin.getOrganisations(),
})

export const addUserOrganisation = (organisation) => ({
  type: ADD_USER_ORGANISATION,
  payload: organisation,
})

export const createOrganisation = (organisation) => ({
  type: CREATED_ORGANISATION,
  payload: organisation,
})

export const fetchFriendSuggestions = () => ({
  type: FETCH_FRIEND_SUGGESTIONS,
  payload: apiClient.user.getFriendSuggestions(),
})

export const fetchFriendRequests = () => ({
  type: FETCH_FRIEND_REQUESTS,
  payload: apiClient.user.getFriendRequests(),
})

export const fetchNotificationSettings = () => ({
  type: FETCH_NOTIFICATION_SETTINGS,
  payload: apiClient.user.getNotificationSettings(),
})

export const fetchCategories = () => ({
  type: FETCH_CATEGORIES,
  payload: apiClient.user.getCategories(),
})

export const patchNotificationSettings = (settings) => ({
  type: PATCH_NOTIFICATION_SETTINGS,
  payload: apiClient.user.patchNotificationSettings(settings),
})

export const removeFriendRequest = (friend) => ({
  type: REMOVE_FRIEND_REQUEST,
  payload: friend,
})

export const removeFollowing = (organisationId) => ({
  type: REMOVE_FOLLOWING,
  payload: organisationId,
})

export const removeFriend = (friendId) => ({
  type: REMOVE_FRIEND,
  payload: friendId,
})

export const patchFriends = (friend) => ({
  type: ADD_FRIEND,
  payload: friend,
})

export const removeFriendSuggestion = (suggestionId) => ({
  type: REMOVE_FRIEND_SUGGESTION,
  payload: suggestionId,
})

export const addFollowing = (organisation) => ({
  type: ADD_FOLLOWING,
  payload: organisation,
})

export const unfollow = (organisationId, patchOrg) => (dispatch) => {
  apiClient.organisation.unfollow(organisationId).then(
    (newOrganisation) => {
      mixpanel.track('Unfollow', { 'Organisation ID': newOrganisation.id })
      dispatch(removeFollowing(organisationId))
      if (patchOrg) dispatch(patchLocalOrganisation(newOrganisation))
    },
    (error) =>
      dispatch(
        setSnackbar(
          'error',
          error.message === 'Follow not found'
            ? 'You already unfollowed'
            : error.message,
        ),
      ),
  )
}

export const addFriend = (friend) => (dispatch) => {
  apiClient.user.addFriends([friend]).then(
    (friends) => {
      dispatch(patchFriends(friends))
    },
    (error) => dispatch(setSnackbar('error', error.message)),
  )
}

export const followUser = (userId) => (dispatch) => {
  apiClient.user.addFriend(userId).then(
    (friends) => {
      // dispatch(patchFriends(friends))
    },
    (error) => dispatch(setSnackbar('error', error.message)),
  )
}

export const unFriend = (friendId) => (dispatch) => {
  apiClient.user.removeFriend(friendId).then(
    (friend) => {
      dispatch(removeFriend(friendId))
    },
    (error) =>
      dispatch(
        setSnackbar(
          'error',
          error.message === 'Friend not found'
            ? 'You already removed this friend'
            : error.message,
        ),
      ),
  )
}

export const follow = () => (dispatch, getState) => {
  const organisationId = getState().organisation.organisation.id
  apiClient.organisation.follow(organisationId).then(
    (newOrganisation) => {
      mixpanel.track('Follow', {
        'Organisation ID': newOrganisation.id,
        'Location in App': 'public profile',
      })
      dispatch(addFollowing(newOrganisation))
      dispatch(patchLocalOrganisation(newOrganisation))
    },
    (error) => dispatch(setSnackbar('error', error.message)),
  )
}

export const followOrganisationId =
  (organisationId) => (dispatch, getState) => {
    apiClient.organisation.follow(organisationId).then(
      (newOrganisation) => {
        mixpanel.track('Follow', {
          'Organisation ID': newOrganisation.id,
          'Location in App': 'public profile',
        })
        dispatch(addFollowing(newOrganisation))
        dispatch(patchLocalOrganisation(newOrganisation))
      },
      (error) => dispatch(setSnackbar('error', error.message)),
    )
  }

export const followId = (organisationId) => async (dispatch) => {
  const newOrganisation = await apiClient.organisation.follow(organisationId)
  mixpanel.track('Follow', {
    'Organisation ID': newOrganisation.id,
    'Location in App': 'suggested profile',
  })
  dispatch(addFollowing(newOrganisation))
}

export const uploadAvatar = (file) => async (dispatch) => {
  dispatch({ type: `${UPLOAD_USER_AVATAR}_PENDING` })
  try {
    const uploadUrl = await apiClient.user.getUploadUrl(file.type)
    // eslint-disable-next-line no-undef
    await fetch(uploadUrl, {
      method: 'PUT',
      header: {
        'Content-Type': 'image/jpeg',
      },
      body: file,
    })
    dispatch({
      type: `${UPLOAD_USER_AVATAR}_FULFILLED`,
      payload: uploadUrl,
    })
  } catch (error) {
    dispatch({
      type: `${UPLOAD_USER_AVATAR}_REJECTED`,
      payload: error,
    })
  }
}

import React, { useEffect, useState } from 'react'
import { Box, Modal, Typography } from '@material-ui/core'
import FlatButton from 'shared-components/buttons/FlatButton'
import Link from 'next/link'

import mixpanel from 'mixpanel-browser'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  box: {
    position: 'fixed',
    bottom: '0',
    width: '100%',
    background: 'white',
    padding: theme.spacing(3),
  },
  accept: {
    marginLeft: theme.spacing(1),
    background: '#600BC1',
    height: theme.spacing(4),
    marginBottom: theme.spacing(1),
    fontSize: 14,
  },
  denied: {
    marginBottom: theme.spacing(1),
    marginLeft: theme.spacing(1),
    borderColor: 'black',
    backgroundColor: 'white',
    color: 'black',
    height: theme.spacing(4),
    fontSize: 14,
  },
  leftComponent: {
    float: 'left',
    width: '50%',
    marginTop: theme.spacing(1),
    '@media (max-width: 978px)': {
      float: 'center',
      width: '100%',
    },
  },
  rightComponent: {
    float: 'right',
    marginTop: theme.spacing(1.5),
    '@media (max-width: 978px)': {
      marginBottom: theme.spacing(-2.5),
    },
    '@media (max-width: 524px)': {
      marginLeft: theme.spacing(-1.5),
    },
  },
  leftComponentCookie: {
    width: theme.spacing(5),
    marginBottom: theme.spacing(1),
    marginRight: theme.spacing(1),
    marginLeft: theme.spacing(-1.5),

    '@media (max-width: 788px)': {
      width: theme.spacing(4),
    },
    '@media (max-width: 628px)': {
      width: theme.spacing(3),
    },
    '@media (max-width: 468px)': {
      width: theme.spacing(2.5),
    },
    '@media (max-width: 388px)': {
      width: theme.spacing(2),
    },
  },

  rightComponentCookie: {
    float: 'right',
    width: '95%',
    marginBottom: theme.spacing(0.5),
  },
}))

export default function CookieModal() {
  const [displayPopUp, setDisplayPopUp] = useState(false)
  const classes = useStyles()

  useEffect(() => {
    const seenPopUp =
      localStorage.getItem('cookies') === 'true' ||
      localStorage.getItem('cookies') === 'false'
    setDisplayPopUp(!seenPopUp)
  }, [])

  const handleAcceptCookies = () => {
    mixpanel.opt_in_tracking()
    localStorage.setItem('cookies', 'true')
    window.dispatchEvent(new Event('storage'))
    setDisplayPopUp(false)
  }

  const handleRejectCookies = () => {
    localStorage.setItem('cookies', 'false')
    window.dispatchEvent(new Event('storage'))
    setDisplayPopUp(false)
  }

  return (
    <div>
      {displayPopUp && (
        <Modal
          style={{ zIndex: 99992 }}
          open={true}
          aria-labelledby='modal-modal-title'
          aria-describedby='modal-modal-description'
        >
          <Box className={classes.box}>
            <img
              className={classes.leftComponentCookie}
              alt='cookie'
              src='/images/cookie_2.png'
            />
            <div className={classes.rightComponentCookie}>
              <Typography
                variant='body2'
                id='modal-modal-description'
                style={{ marginBottom: '10px', lineHeight: '17px' }}
              >
                BASH uses cookies on this website to be able to assist you
                better and more personally. We use analytical cookies to view
                and improve the quality and effectiveness of our website. We can
                also use these cookies for other (commercial) purposes, for
                which we request permission.
              </Typography>

              <Typography variant='body2' className={classes.leftComponent}>
                Read our{' '}
                <strong>
                  <Link href='/cookienotice'>Cookie Notice</Link>
                </strong>{' '}
                and{' '}
                <strong>
                  <Link href='/privacy'>Privacy Policy</Link>
                </strong>{' '}
                here.
              </Typography>
              <div className={classes.rightComponent}>
                <FlatButton
                  className={classes.accept}
                  onClick={handleAcceptCookies}
                >
                  Accept all cookies
                </FlatButton>
                <FlatButton
                  className={classes.denied}
                  onClick={handleRejectCookies}
                  variant='outlined'
                >
                  Accept essential cookies only
                </FlatButton>
              </div>
            </div>
          </Box>
        </Modal>
      )}
    </div>
  )
}

import typeToReducer from 'type-to-reducer'
import {
  ADD_IMAGE_CHAT_MODAL,
  CLOSE_MODAL,
  OPEN_DATE_OPTION_RESPONSES,
  OPEN_GUEST_INFO,
  OPEN_MODAL,
  OPEN_MODAL_CHAT,
  OPEN_MODAL_PAGE,
  OPEN_MODAL_SIGNIN,
  OPEN_OPEN_IN_APP,
  OPEN_USER_INFO,
  REFRESH_PAGE,
  REMOVE_ANIM,
  SET_DISCOVER_DATE,
  SET_DOWNLOAD_USE_CONTINUE_IN_APP,
  SET_EDITING_POST,
  SET_EVENT_UPDATES_MODAL,
  SET_IMAGES_CHAT_MODAL,
  SET_MODAL_PAGE,
  SET_MODAL_TEXT_KEY,
  SHOW_ANIM,
  TOGGLE_SEARCH,
} from '../actions/modals'
import { OPEN_RSVP_ON_STEP, START_CHECK_ORDER_STATUS } from '../actions/rsvp'
import {
  DELETE_GUEST_FROM_INFO_SHEET,
  PATCH_GUEST_FROM_INFO_SHEET,
  PIN_DATE,
} from '../actions/event'
import { property, uniq } from 'underscore'

export const defaultEditingPost = {
  text: '',
  imageFiles: [],
  fromOrganisation: null,
  eventUpdates: [],
  media: [],
}

const initialState = {
  open: {
    guest: false,
    download: false,
    follow: false,
    rsvp: false,
    signIn: false,
    feedback: false,
    inviteGuests: false,
    bashPro: false,
    dateOptions: false,
    guestInfo: false,
    userInfo: false,
    message: false,
    addToCalendar: false,
    followUser: false,
    privateEvent: false,
    pinDate: false,
    reachability: false,
    openInBash: false,
    verifyEmailOld: false,
    verifyEmail: false,
    share: false,
    chat: false,
    hyped: false,
    tickets: false,
    guestManagement: false,
    logOut: false,
  },
  autoFocusImageInput: false,
  emailAddress: null,
  replyTo: null,
  imageFiles: [],
  eventUpdates: [],
  discoverDate: null,
  animation: {
    bee: false,
  },
  page: {
    signIn: 0,
    guestManagement: 'guestList',
  },
  textKeys: {
    download: {
      title: {
        key: 'downloadDialogEvent.title',
        options: null,
      },
      description: {
        key: 'downloadDialogEvent.description',
        options: null,
      },
    },
  },
  download: {
    useContinueInApp: true,
  },
  activeDateOption: null,
  activeInfoGuest: null,
  activeInfoUser: null,
  editingPost: defaultEditingPost,
  refreshList: {
    name: 'guestList',
    counter: 0,
  },
}

export default typeToReducer(
  {
    [REFRESH_PAGE]: (state, action) => ({
      ...state,
      refreshList: {
        name: action.payload,
        counter: state.refreshList.counter + 1,
      },
    }),
    [SET_DISCOVER_DATE]: (state, action) => ({
      ...state,
      discoverDate: action.payload,
    }),
    [OPEN_MODAL]: (state, action) => ({
      ...state,
      open: {
        ...state.open,
        [action.payload]: true,
      },
    }),
    [SHOW_ANIM]: (state, action) => ({
      ...state,
      animation: {
        ...state.animation,
        [action.payload]: true,
      },
    }),
    [REMOVE_ANIM]: (state, action) => ({
      ...state,
      animation: {
        ...state.animation,
        [action.payload]: false,
      },
    }),
    [OPEN_MODAL_PAGE]: (state, action) => ({
      ...state,
      open: {
        ...state.open,
        [action.payload.modal]: true,
      },
      page: {
        [action.payload.modal]: action.payload.page,
      },
    }),
    [OPEN_MODAL_CHAT]: (state, action) => ({
      ...state,
      open: {
        ...state.open,
        [action.payload.modal]: true,
      },
      autoFocusImageInput: action.payload.autoFocusImageInput,
      replyTo: action.payload.replyTo,
      imageFiles: action.payload.imageFiles ?? [],
    }),
    [OPEN_MODAL_SIGNIN]: (state, action) => ({
      ...state,
      open: {
        ...state.open,
        [action.payload.modal]: true,
      },
      emailAddress: action.payload.emailAddress,
      organisationToFollow: action.payload.organisationToFollow,
      allowClose: action.payload.allowClose,
      rsvpAfterSignIn: action.payload.rsvpAfterSignIn,
      stripeCustomerId: action.payload.stripeCustomerId,
      hypeAfterSignIn: action.payload.hypeAfterSignIn,
    }),
    [SET_MODAL_PAGE]: (state, action) => ({
      ...state,
      page: {
        [action.payload.modal]: action.payload.page,
      },
    }),
    [SET_MODAL_TEXT_KEY]: (state, action) => ({
      ...state,
      textKeys: {
        ...state.textKeys,
        [action.payload.modal]: action.payload.key,
      },
    }),
    [SET_DOWNLOAD_USE_CONTINUE_IN_APP]: (state, action) => ({
      ...state,
      download: {
        useContinueInApp: action.payload.useContinueInApp,
      },
    }),
    [CLOSE_MODAL]: (state, action) => ({
      ...state,
      open: {
        ...state.open,
        [action.payload]: false,
      },
      autoFocusImageInput: false,
      replyTo: null,
      imageFiles: [],
    }),
    [ADD_IMAGE_CHAT_MODAL]: (state, action) => ({
      ...state,
      imageFiles: uniq(
        [...state.imageFiles, ...action.payload],
        false,
        property('id'),
      ),
    }),
    [SET_IMAGES_CHAT_MODAL]: (state, action) => ({
      ...state,
      imageFiles: uniq(action.payload, false, property('id')),
    }),
    [SET_EVENT_UPDATES_MODAL]: (state, action) => ({
      ...state,
      eventUpdates: action.payload,
    }),
    [TOGGLE_SEARCH]: (state, action) => ({
      ...state,
      search: action.payload,
    }),
    [OPEN_DATE_OPTION_RESPONSES]: (state, action) => ({
      ...state,
      open: {
        ...state.open,
        dateOptions: true,
      },
      activeDateOption: action.payload || state.activeDateOption,
    }),
    [OPEN_GUEST_INFO]: (state, action) => ({
      ...state,
      open: {
        ...state.open,
        guestInfo: true,
      },
      activeInfoGuest: action.payload,
    }),
    [OPEN_USER_INFO]: (state, action) => ({
      ...state,
      open: {
        ...state.open,
        guestInfo: false,
        userInfo: true,
      },
      activeInfoUser: action.payload,
    }),
    [OPEN_OPEN_IN_APP]: (state, action) => ({
      ...state,
      open: {
        ...state.open,
        guestInfo: false,
        openInApp: true,
      },
    }),
    [START_CHECK_ORDER_STATUS]: (state) => ({
      ...state,
      open: {
        ...state.open,
        rsvp: true,
      },
    }),
    [OPEN_RSVP_ON_STEP]: (state) => ({
      ...state,
      open: {
        ...state.open,
        rsvp: true,
      },
    }),
    [SET_EDITING_POST]: (state, action) => ({
      ...state,
      editingPost: action.payload,
    }),
    [`${PIN_DATE}_FULFILLED`]: (state) => ({
      ...state,
      open: {
        ...state.open,
        dateOptions: false,
      },
    }),
    [`${PATCH_GUEST_FROM_INFO_SHEET}_FULFILLED`]: (state, action) => ({
      ...state,
      activeInfoGuest: action.payload,
    }),
    [`${DELETE_GUEST_FROM_INFO_SHEET}_FULFILLED`]: (state, action) => ({
      ...state,
      open: {
        ...state.open,
        guestInfo: false,
      },
    }),
  },
  initialState,
)
